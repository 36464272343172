<template>
  <div class='agree-wrap'>
    <div class='main'>
      <div class='agreement-title'>
        <span>签署课程协议</span>
      </div>
      <el-card class='box-card' v-loading="agreeLoading">
        <template #header>
          <div class="card-header">
            <div class='card-title'>
              <svg-icon icon-class='layer' class='icon-card'></svg-icon>
              <span>协议内容</span>
            </div>
          </div>
        </template>
        <div class='agreement-con'>
          <div v-html='agreeData'></div>
        </div>
      </el-card>
      <div class='agreement-sub'>
        <el-card class='box-card'>
          <div class='agreement-checked'>
            <el-checkbox
              v-model="agreementChecked"
              label="我同意以上条款"></el-checkbox>
          </div>
          <!-- <ul class='agreement-list'>
            <li>1、用户报名《VIP套餐协议》辅导协议必须签署协议，否则不能够享受后续服务。</li>
            <li>2、用户在《VIP套餐协议》辅导协议中，签署协议信息必须真实，且与用户信息一致。</li>
          </ul> -->
          <div class='agreement-form'>
            <el-form
              label-width="80px"
              :model="agreementForm"
              
              ref="agreementForm"
              class="demo-ruleForm">
              <el-form-item label="用户姓名" prop="name">
                <el-input v-model="agreementForm.name" clearable></el-input>
              </el-form-item>
              <el-form-item label="身份证号" prop="idCard">
                <el-input v-model="agreementForm.idCard" clearable></el-input>
              </el-form-item>
              <el-form-item label="联系方式" prop="phone">
                <el-input v-model="agreementForm.phone" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  @click="subAgreementForm('agreementForm')"
                  type="danger" >确认签署协议</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </div>
    </div>
    <to-top></to-top>
  </div>
</template>

<script>
  import {validTelphone,validIdcard} from '@/utils/validate'
  import {getAgree,signAgree,getMerInfo} from '@/api/user'
  import {getComboGrade,getCourseSection} from '@/api/course'
  import {getSingleSpec} from '@/api/exam'

  var validPhone = (rule, value,callback)=>{
      if (!value){
          callback(new Error('请输入联系方式'))
      }else  if (!validTelphone(value)){
        callback(new Error('手机号码格式错误'))
      }else {
          callback()
      }
  }
  var validCard = (rule, value,callback)=>{
      if (!value){
          callback(new Error('请输入身份证号'))
      }else  if (!validIdcard(value)){
        callback(new Error('身份证号格式错误'))
      }else {
          callback()
      }
  }
  export default{
    props:['productid','type','agreementid'],
    name:'courseAgreement',
    data(){
      return {
        agreeData:'',
        agreementChecked:false,
        agreeLoading:true,
        agreementForm:{
          name:'',
          idCard:'',
          phone:''
        },
        agreementRules:{
          name: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
          ],
          idCard: [
            {
              type:'number',
              required: true,
              validator: validCard,
              trigger: 'blur' }
          ],
          phone: [
            {
              type:'number',
              required: true,
              validator: validPhone,
              trigger: 'blur' }
          ],
        },
        agreementData:{
          student:'',
          exam_name:'',
          product_name:'',
          product:[],
          company_name:'',
          agency_name:'',
          email:'',
          agency_tel:'',
          city:''
        }
      }
    },
    watch:{

    },
    mounted() {
      let _this = this;
      if(this.type==1){//套餐信息
        getComboGrade({
          id:this.productid
        }).then(res=>{
          if(res.code==1){
             _this.agreementData.product_name = res.data.title;
             _this.agreementData.product = res.data.course;
             getSingleSpec({
               id:res.data.spec_id
             }).then(res2=>{
              if(res2.code==1){
                _this.agreementData.exam_name = res2.data.title;
                getMerInfo().then(res=>{//商户信息
                  if(res.code==1){
                    let merinfo = res.data.info;
                    this.agreementData.company_name = merinfo.company_name;
                    this.agreementData.agency_name = merinfo.sitename;
                    this.agreementData.email = res.data.email;
                    this.agreementData.agency_tel = merinfo.tel;
                    this.agreementData.city = merinfo.address;
                    getAgree({
                      id:this.agreementid
                    }).then(res=>{
                      if(res.code==1){
                        let agreementCon = res.data.content;//富文本内容
                        let reg = /{exam_name}|{product_name}|{company_name}|{agency_name}|{email}|{agency_tel}|{city}|{student}/g;
                        var result = agreementCon.replace(reg, function(matchStr) {
                            var tokenMap = {
                                '{exam_name}': _this.agreementData.exam_name,
                                '{product_name}': _this.agreementData.product_name,
                                '{company_name}': _this.agreementData.company_name,
                                '{agency_name}': _this.agreementData.agency_name,
                                '{email}': _this.agreementData.email,
                                '{agency_tel}': _this.agreementData.agency_tel,
                                '{city}': _this.agreementData.city,
                                '{student}': _this.agreementData.student
                            };
                            return tokenMap[matchStr];
                        });
                        //动态创建班级节点
                        let Ul = $('<ul></ul>');
                        _this.agreementData.product.map((item,index)=>{
                          item.grades.map((list,lindex)=>{
                            let Li = $("<li></li>").html(list.title);
                            Ul.append(Li);
                          })
                        });
                        this.agreeLoading = false;
                        this.agreeData = this.replaceTextFn(result,'{product}',Ul[0].outerHTML);
                      }
                    });
                  }
                });
              }
             })
          }
        })
      }
    },
    methods:{
      replaceTextFn(content, originText, targetText) {
        let reg = /(?<=>)(.|\s)*?(?=<\/?\w+[^<]*>)/g;
        let resContent = content.replace(reg,item => {
          return item.replace(new RegExp(originText, 'g'), targetText)
        })
        return resContent;
      },
      subAgreementForm(formName){
        if(this.agreementChecked){
          //this.$refs[formName].validate((valid) => {
            //if (valid) {
              //提交协议
              signAgree({
                product_id:this.productid,
                type:this.type,
                agreement_id:this.agreementid,
                tel:this.agreementForm.name,
                IDNo:this.agreementForm.idCard,
                username:this.agreementForm.phone
              }).then(res=>{
                if(res.code==1){
                  this.$notify({
                    title: '签署成功',
                    message: '开始学习吧',
                    type: 'success',
                    duration:1500,
                    showClose:false
                  });
                  this.$router.push({
                    path:'/user/mycourse'
                  });
                }
              })
            //}else{
              //console.log('error submit!!');
              //return false;
            //}
          //});
        }else{
          this.$notify.error({
           	title: '未同意协议',
           	message: '请先同意协议',
           	duration:1500,
           	showClose:false
          });
        }
      }
    }
  }



</script>

<style scoped="scoped" lang="scss">
  .agreement-sub .el-checkbox__inner{
    border:1px solid #000;
  }
  .el-checkbox__input.is-checked+.el-checkbox__label{
    color:red;
  }
  .box-card{
    padding: 10px 20px;
    margin-bottom:10px;
    .card-header {
      padding-bottom: 5px;
    }
  }
  .agree-wrap{
    .agreement-title{
      padding:20px 0px;
      font-size:16px;
      border-bottom:2px solid $theme-color;
    }
    .agreement-con {
      padding: 10px 0;
    }
    .agreement-sub{
      .agreement-list{
         margin-top:15px;
         li{
           color:red;
           padding:5px 0px;
         }
       }
       .agreement-form{
         margin-top:20px;
         width:500px;
       }
    }
  }

</style>
