import request from '@/utils/request'
import qs from 'qs';

/**
 * 查询网站信息
 * @returns 
 */
export function getProSpec(data) {//行业分类
  return request({
    url: '/product/spec',
    method: 'post',
    data:qs.stringify(data)
  })
}

export function getComboBySpec(data) {//按二级科目查询套餐，新闻，题库
  return request({
    url: '/product/getComboBySpec',
    method: 'post',
    data:qs.stringify(data)
  })
}

export function getComboByClass(data) {//按二级科目查询套餐
  return request({
    url: '/product/getComboByClass',
    method: 'post',
    data:qs.stringify(data)
  })
}

export function comboByCourse(data) {//根据班型查询套餐
  return request({
    url: '/product/comboByCourse',
    method: 'post',
    data:qs.stringify(data)
  })
}


export function getComboGrade(data) {//输入套餐ID查找班级
  return request({
    url: '/product/single',
    method: 'post',
    data:qs.stringify(data)
  })
}

export function isGou(data) {//某个产品是否购买
  return request({
    url: '/isGou',
    method: 'post',
    data:qs.stringify(data)
  })
}

export function isGouGrade(data) {//班级是否购买
  return request({
    url: '/isGouGrade',
    method: 'post',
    data:qs.stringify(data)
  })
}

export function getGradeSections(data) {//单个班级的课程
  return request({
    url: '/product/section',
    method: 'post',
    data:qs.stringify(data)
  })
}

export function collectionCombo(data) {//收藏课程，套餐
  return request({
    url: '/user/collCombo',
    method: 'post',
    data:qs.stringify(data)
  })
}

export function cancleCollection(data) {//取消收藏,type,id
  return request({
    url: '/user/cancleFav',
    method: 'post',
    data:qs.stringify(data)
  })
}

export function getDownload(data) {//下载讲义
  return request({
    url: '/product/material',
    method: 'post',
    data: qs.stringify(data)
  })
}


export function getPlaysafe(data) {//创建 Playsafe Token
  return request({
    url: '/getPlaysafeToken',
    method: 'post',
    data:qs.stringify(data)
  })
}

export function integrity(data) {//视频播放完毕，发送播放完毕视频数据到后台
  return request({
    url: 'https://api.polyv.net/v2/video/engagement/{userid}/get?ptime=' + data.ptime + "&vid=" + data.vid + "&userid=" + data.userid,
    method: 'get'
  })
}

export function getUserPlayPlan(data) {//用户某个课程的观看记录(班级) ：user/secHistory?grade_id=1
	return request({
		url: '/user/secHistory',
		method: 'post',
		data: qs.stringify(data)
	})
}

export function userPlayPlanUpload(data) {//课程的观看记录上传 ：/user/signSec?section_id=3&grade_id=1&end=120&duration=14:02
	return request({
		url: '/user/signSec',
		method: 'post',
		data: qs.stringify(data)
	})
}

export function getLessionSrc(data) {//获取其他平台视频地址
  return request({
    url: '/Shanghai.Video/getVideo',
    method: 'post',
    data:qs.stringify(data)
  })
}
